export default {
  ko: {
    cneeName: "수하인명",
    cneeTel: "수하인 연락처",
    consignee:"수하인",
    tel:"연락처",
    cneeAddr: "수하인주소",
    buySite: "수하인 구매사이트",
    realBuySite: "Shipper 구매사이트",
    expectWt: "예상 무게",
    aciWt: "ACI 무게",
    itemImgUrl: "상품 이미지",
    itemBrand: "상품 브랜드",
    itemId: "상품 ID",
    itemDetail: "상품명",
    foodDivi: "식품구분",
    trkNo: "트래킹 번호",
    siteId: "사서함번호",
    siteOrderNo: "사이트 주문번호",
    nno: "관리번호",
    whComment: "요청메모",
    podTitle: "화물추척",
    mallType: "상거래 유형",
    addButton: "검품등록 - 수기",
    addByExcelButton: "검품등록 - 엑셀",
    whStatus: "상세상태",
    unitValue: "Net Value",
    editTrkNo: "트래킹 번호 수정",
    itemQty: "발생수량",
    stockQty: "재고수량",
    stockNo: "재고 번호",
    whDate: "입고일자",
    orderDate: "등록(주문)일자",
    retInfo: "반품정보",
    stockStatus: "재고 상태",
    reqStatus: "요청 상태",
    hsCode: "HS Code",
    customsNo: "개인통관고유부호(PCCC)",
    cneeHp: "휴대폰번호",
    makeCountry: "제조국",
    makeCompany: "제조회사명",
    trkCom: "택배사",
    itemColor: "상품 색상",
    itemSize: "상품 사이즈",
    itemUrl: "상품 URL",
    inComment: "입고 메모",
    reqComment: "요청 메모",
    userWta: "WT(A)",
    userWtc: "WT(C)",
    reqOrderDate: "대체출고주문일자",
    reoQty: "대체출고수량",
    reoHawbNo: "대체출고 운송장번호",
    reoOrderNo: "대체출고 주문번호",
    reoCneeName: "대체출고 수하인",
    attnCode: "담당자",
    dlvComment: "배송 메모",
    orderNo: "고객주문번호",
    inputTrkNo: "트래킹번호입력",
    imgUrl:"검수이미지",
    inspection:"검수",
    img:"이미지",
    alertTrkNoSave: "트래킹번호가 저장되었습니다",
    addDialog: {
      title: "검품등록 - 수기",
      editTitle: "상세조회/수정",
      consignee: {
        title: "수하인",
        cneeTel: "유선전화",
        cneeZip: "우편번호(5자리)",
        cneeAddr2: "상세주소",
      },
      orderInfo: {
        title: "주문정보",
        orderNo: "고객주문번호",
        getBy: "용도구분",
        realBuySite: "판매자 구매사이트 (구매대행업자)",
      },
      orderItemList: {
        title: "상품정보",
        unitValue: "Net Value (USD)",
        hsCodeButtonTitle: "HS CODE",
      },
      whReqList: {
        title: "요청사항",
        memo: "메모 내용",
      },
    },
    excelDialog: {
      title: "검품등록 - 엑셀",
      saveButton: "배송자료 선택등록",
      saveAllButton: "배송자료 전체등록",
      saveButtonConfirm: "배송자료를 등록하시겠습니까?",
      exceptRowTitle: "열이름",

      orderDate: "등록(주문)일자",
      orderNo: "고객주문번호",
      cneeName: "수하인",
      cneeTel: "수하인TEL",
      cneeAddr: "수하인주소",
      buySite: "수하인 구매사이트",
      mallType: "상거래유형",
      expectWt: "예상무게",
      itemDetail: "내용물",
    },

    inventory: {
      inspectionResult: "검품결과",
      tabCountTextLine1: "[통합검색 필터로 인한 목록별 조회건수]",
      tabCountTextLine2: "@:inspection.inventory.outgoing.tabTitle => {text}건",
      tabCountTextLine3: "@:inspection.inventory.stock.tabTitle => {text}건",
      tabCountTextLine4: "@:inspection.inventory.return.tabTitle => {text}건",
      outgoing: {
        tabTitle: "출고대상 목록",
        outgoingButtonTitle: "출고 요청",
        returnButtonTitle: "반품 요청",
        stockButtonTitle: "재고변환 요청",
        cancelButtonTitle: "취소 요청",
        requestedItemWarning:
          "이미 요청등록/처리 한 상품은  재요청 등록 할 수 없습니다",
        commentDialogOUTRHint:
          "요청사항 등록 후 '저장' 버튼을 클릭하시면 출고요청 됩니다.",
        commentDialogRETRHint:
          "요청사항 등록 후 '저장' 버튼을 클릭하시면 반품요청 됩니다.",
        commentDialogSTKRHint:
          "요청사항 등록 후 '저장' 버튼을 클릭하시면 재고변환요청 됩니다.",
        commentDialogCancelHint:
          "'저장' 버튼을 클릭하시면 선택한 요청내이 취소 됩니다.",
        memoDialog: {
          title: "요청메모/입고메모",
        },
      },
      stock: {
        tabTitle: "재고 목록",
        substituteOutgoingButtonTitle: "대체출고 요청",
        substituteOutgoingDialogTitle: "대체출고 요청 등록",
        returnButtonTitle: "반품 요청",
        orderInfo: "원 주문정보",
        substituteOutgoingDialogSaveButtonTitle: "배송자료등록",
        outgoingButtonWarning:
          "이미 요청등록/처리 한 상품은 등록 할 수 없습니다.",
        returnButtonWarning:
          "요청상태가 'N/A' 또는 '재고변환처리' 인 경우에만 가능합니다.",
      },
      return: {
        tabTitle: "반품 목록",
        cusReturn: "반품Carrier",
        returnDate: "반품Shipping 일자",
        cusReturnTrkno: "반품 트래킹번호",
      },
    },
  },
  en: {},
  zhHans: {},
};
