import type { Drawer } from "@/definitions/types";
import { MenuType } from "@/definitions/selections";

export const DRAWERS: Drawer[] = [
  {
    id: "PURCHASE",
    type: MenuType.GROUP,
    icon: "mdi-basket-outline",
    children: [
      {
        id: "PURCHASE_TAKE_IN_CODE",
        type: MenuType.PAGE,
        url: "/purchase/take-in-code",
      },
      {
        id: "PURCHASE_WAYBILL_REGISTRATION",
        type: MenuType.PAGE,
        url: "/purchase/take-in-order",
      },
      {
        id: "PURCHASE_SHIPPING_LIST",
        type: MenuType.PAGE,
        url: "/purchase/shipping",
      },
      {
        id: "PURCHASE_INVENTORY",
        type: MenuType.PAGE,
        url: "/purchase/inventory",
      },
    ],
  },
  {
    id: "INSPECTION",
    type: MenuType.GROUP,
    icon: "mdi-check-decagram-outline",
    children: [
      {
        id: "INSPECTION_LIST",
        type: MenuType.PAGE,
        url: "/inspection/list",
      },
      {
        id: "INSPECTION_LIST_NEW",
        type: MenuType.PAGE,
        url: "/inspection/newList",
      },
      {
        id: "INSPECTION_INVENTORY",
        type: MenuType.PAGE,
        url: "/inspection/inventory",
      },
    ],
  },
  {
    id: "WAYBILL",
    type: MenuType.GROUP,
    icon: "mdi-note-plus-outline",
    children: [
      {
        id: "WAYBILL_EXCEL_REGISTRATION",
        type: MenuType.PAGE,
        url: "/waybill/excel-registration",
      },
      {
        id: "WAYBILL_HANDWRITTEN_REGISTRATION",
        type: MenuType.PAGE,
        url: "/waybill/registration",
      },
      {
        id: "WAYBILL_PRINT",
        type: MenuType.PAGE,
        url: "/waybill/print",
      },
      {
        id: "WAYBILL_OUTGOING_CONFIRMATION",
        type: MenuType.PAGE,
        url: "/waybill/outgoing-confirmation",
      },
      {
        id: "WAYBILL_TOTAL_TAXATION_CHECK",
        type: MenuType.PAGE,
        url: "/waybill/total-taxation-check",
      },
    ],
  },
  {
    id: "SHIPPING",
    type: MenuType.GROUP,
    icon: "mdi-receipt-text-send-outline",
    children: [
      {
        id: "SHIPPING_LIST",
        type: MenuType.PAGE,
        url: "/shipping/list",
      },
      {
        id: "SHIPPING_DAILY_SHIPMENT_STATUS",
        type: MenuType.PAGE,
        url: "/shipping/daily",
      },
      {
        id: "SHIPPING_DELETED_ITEMS",
        type: MenuType.PAGE,
        url: "/shipping/deleted-items",
      },
      {
        id: "SHIPPING_COMMERCIAL",
        type: MenuType.PAGE,
        url: "/shipping/commercial",
      },
    ],
  },
  {
    id: "DELIVERY",
    type: MenuType.GROUP,
    icon: "mdi-truck-delivery-outline",
    children: [
      {
        id: "DELIVERY_STATUS",
        type: MenuType.PAGE,
        url: "/delivery/status",
      },
      {
        id: "DELIVERY_DUTY_SURTAX",
        type: MenuType.PAGE,
        url: "/delivery/vat",
      },
      {
        id: "DELIVERY_UNCUSTOMS",
        type: MenuType.PAGE,
        url: "/delivery/uncustoms",
      },
      {
        id: "DELIVERY_UNDELIVERED",
        type: MenuType.PAGE,
        url: "/delivery/undelivered",
      },
      {
        id: "DELIVERY_DELAY",
        type: MenuType.PAGE,
        url: "/delivery/delay",
      },
    ],
  },
  {
    id: "RETURN",
    type: MenuType.GROUP,
    icon: "mdi-archive-refresh-outline",
    children: [
      {
        id: "RETURN_MANAGEMENT",
        type: MenuType.PAGE,
        url: "/return/management",
      },
      {
        id: "RETURN_RECEIVING",
        type: MenuType.PAGE,
        url: "/return/receiving",
      },
      {
        id: "RETURN_UNRECEIVING",
        type: MenuType.PAGE,
        url: "/return/unreceiving",
      },
      {
        id: "RETURN_SHIPPING",
        type: MenuType.PAGE,
        url: "/return/shipping",
      },
    ],
  },
  {
    id: "FTA",
    type: MenuType.GROUP,
    icon: "mdi-handshake-outline",
    children: [
      {
        id: "FTA_FTA",
        type: MenuType.PAGE,
        url: "/fta/fta",
      },
    ],
  },
  {
    id: "MY_PAGE",
    type: MenuType.GROUP,
    icon: "mdi-account-box-outline",
    children: [
      {
        id: "MY_PAGE_MY_INFO",
        type: MenuType.PAGE,
        url: "/my-page/my-info",
      },
      {
        id: "MY_PAGE_EXCEL_FIELD",
        type: MenuType.PAGE,
        url: "/my-page/excel-field",
      },
      {
        id: "MY_PAGE_INVOICE",
        type: MenuType.PAGE,
        url: "/my-page/invoice",
      },
    ],
  },
  {
    id: "BOARD",
    type: MenuType.GROUP,
    icon: "mdi-bulletin-board",
    children: [
      {
        id: "BOARD_QNA",
        type: MenuType.PAGE,
        url: "/board/qna",
      },
      {
        id: "BOARD_NOTICE",
        type: MenuType.PAGE,
        url: "/board/notice",
      },
    ],
  },
];
